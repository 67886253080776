import styled from 'styled-components'
import Grid from '@mui/material/Grid'

import { projects } from './config'

const Wrapper = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 50px;
  padding-bottom: 100px;
`
const Project = styled.a`
  text-align: center;
  /* width: calc(1250px / 7); */
  display: black;
  color: black;
  > div {
    background: rgba(0,0,0,0.05);
    border-radius: 30px;
    padding: 20px;
    margin: 30px auto;
    transition: all 0.3s;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
  p {
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
  }
  &:nth-of-type(7) {
    margin-right: 5;
  }
  &:hover {
    > div {
      transform: scale(1.1);
    }
  }
`
export const Container = styled.div`
  max-width: 1200px;
  > p {
    height: 1px;
    background-color: #CFCCC2;
  }
`
export default function Portgolio() {
  return (
    <Container>
      <p></p>
      <Wrapper>
        <Grid container spacing={{ xs: 3, md: 6, sm: 3 }} columns={{ xs: 3.42, sm: 8.55, md: 12}}>
          {
            projects.map((i, k) => {
              return (
                <Grid item xs={1.71}>
                  <Project key={k} href={i.url} target="_blank">
                    <div>
                      <img src={i.icon} alt="" />
                    </div>
                    <p>{i.name}</p>
                  </Project>
                </Grid>
              )
            })
          }
        </Grid>
      </Wrapper>
    </Container>
  )
}
