import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import logo from '../../assets/images/logo.svg';

const Wrapper = styled.header`
  text-align: left;
  height: 108px;
  line-height: 108px;
  display: flex;
  @media screen and (max-width: 600px) {
    height: 78px;
    line-height: 78px;
  }
  > a {
    display: flex;
    align-items: center;
  }
  img {
    height: 42px;
    @media screen and (max-width: 600px) {
      height: 30px;
    }
  }
  nav {
    flex: 1;
    text-align: right;
    a {
      padding: 11px 13px;
      font-size: 16px;
      background-color: rgba(0, 0, 0, 0.1);
      color: #000;
      border-radius: 6px;
      margin-left: 20px;
      &.active {
        color: #fff;
        background-color: #000000;
        
      }
      @media screen and (max-width: 600px) {
        font-size: 12px;
        padding: 10px;
      }
    }
  }
`

export default function Header() {
  return (
    <Wrapper>
      <NavLink to="/">
        <img src={logo} alt="logo" />
      </NavLink>
      <nav>
        <NavLink to="/team">TEAM</NavLink>
        <NavLink to="/portgolio">PORTFOLIO</NavLink>
      </nav>
    </Wrapper>
  )
}
