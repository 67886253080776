import { useRef, useEffect } from 'react'
import styled from 'styled-components'
import Typed from 'typed.js'

import Footer from '../../components/Footer'

import Pic from '../../assets/images/home.png'

const Wrapper = styled.div`
  > div {
    display: flex;
    padding: 0 60px;
    > img {
      margin-left: 60px;
      margin-top: 60px;
    }
    > div > p {
      font-size: 16px;
      @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
      }
    }
  }
  @media screen and (min-width: 1920px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 1200px) {
    > div {
      flex-direction: column;
      align-items: center;
      padding: 0;
      > img {
        margin-left: 0;
      }
    }
  }
  @media screen and (max-width: 600px) {
    > div {
      > img {
        margin-left: 0;
        width: 100%;
      }
    }
  }
`
const Title = styled.div`
  margin-top: 98px;
  margin-bottom: 40px;
  p {
    width: 539px;
    &:first-child {
      font-size: 70px;
      letter-spacing: 7px;
      line-height: 70px;
    }
    &:last-child {
      font-size: 40px;
      line-height: 40px;
      letter-spacing: 30px;
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 23px;
    p {
      width: 100%;
      text-align: center;
      &:first-child {
        font-size: 40px;
      }
      &:last-child {
        font-size: 14px;
        letter-spacing: 22px;
        margin-top: 0px;
      }
    }
  }
`

const Parallelogram = styled.div`
  border: 1px solid #000000;
  padding: 22px;
  width: 525px;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: -1px;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 12px;
  }
`

const CDL = styled.p`
  font-size: 20px;
  margin: 80px 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`

export default function Home() {
  const el = useRef(null)
  const typed = useRef(null)

  useEffect(() => {
    const options = {
    	strings: [
        'Composability',
        'Democratization',
        'Liquidity'
      ],
      typeSpeed: 80,
      backSpeed: 80,
      loop: true
    };
    // @ts-ignore
    typed.current = new Typed(el.current, options);
    
    return () => {
      // @ts-ignore
      typed.current.destroy();
    }
  }, [])

  return (
    <Wrapper>
      <div>
        <div>
          <Title>
            <p className="p1">NOTHING</p>
            <p className="p2">RESEARCH</p>
          </Title>
          <Parallelogram>A multi-strategy venture</Parallelogram>
          <p>investing open finance initiatives</p>
        </div>
        <img width={'478px'} src={Pic} alt="" />
      </div>
      <CDL>
        <div ref={el}></div>
      </CDL>
      <Footer />
    </Wrapper>
  )
}
