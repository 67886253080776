import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import theme from './theme'
import { ThemeProvider } from '@mui/material/styles'

import Header from './components/Header'

import Home from './pages/Home'
import Team from './pages/Team'
import Portgolio from './pages/Portgolio'


function App() {
  // const location = useLocation()
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/team" element={<Team />} />
            <Route path="/portgolio" element={<Portgolio />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
