import styled from 'styled-components'

import t from '../../assets/images/t.svg';
import e from '../../assets/images/e.svg';

const Wrapper = styled.header`
  height: 3.75rem;
  margin-top: -3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #000;
    text-decoration: none;
    margin: 0 0.75rem;
    font-size: 1rem;
    img {
      width: 1.75rem;
    }
  }
`

export default function Footer() {
  return (
    <Wrapper>
      <a target="_blank" href="https://twitter.com/ResearchNothing" rel="noreferrer"><img src={t} alt="Twitter"/> <span>Twitter</span></a> 
      <a href="mailto:contact@nothing-research.com"><img src={e} alt="Email"/> <span>Email</span></a> 
    </Wrapper>
  )
}
