import Bonna1Pic from '../../assets/images/bonna1.svg'
import BonnaPic from '../../assets/images/bonna.svg'
import Todd1Pic from '../../assets/images/todd1.svg'
import ToddPic from '../../assets/images/todd.svg'
import Allen1Pic from '../../assets/images/allen1.svg'
import AllenPic from '../../assets/images/allen.png'
import Tony1Pic from '../../assets/images/tony1.svg'
import TonyPic from '../../assets/images/tony.svg'

export const peoples = [
  {
    name: 'Bonna Zhu',
    icon: Bonna1Pic,
    img: BonnaPic,
    desc: 'Years in investment banking and private equity before fully into blockchain and digital asset space in 2017. Core contributor to Divergence Protocol, previously head of BD Asia at AscendEx, with former experiences from Huobi Global. Bonna is well connected in the space with tons of experience investing and working with early stage blockchain start-ups.',
  },
  {
    name: 'Todd Ma',
    icon: Todd1Pic,
    img: ToddPic,
    desc: 'Seasoned miner and proactive yield farmer with fruitful insights into digital asset market dynamics. Former chief research analyst at Huobi Global. Todd Ma is also an active blogger and writer that co-authored various best-selling books. ',
  },
  {
    name: 'Allen Ding',
    icon: Allen1Pic,
    img: AllenPic,
    desc: 'STAKING & TOKENOMICS SPECIALIST. 5 YEARS OF DIGITAL ASSET MARKET EXPERIENCE WITH RICH INDUSTRY RESOURCES. FORMER HEAD OF STAKING OPERATIONS AT HUOBI GLOBAL, CURRENTLY SENIOR INSTRUCTOR AT HUOBI UNIVERSITY. ALLEN ALSO SERVES AS ANGEL INVESTORS AND CONTRIBUTORS TO MULTIPLE PROJECTS IN DEFI.',
  },
  {
    name: 'Tony Wei',
    icon: Tony1Pic,
    img: TonyPic,
    desc: 'Community growth expert. 3 years of professional experience working in top digital asset market institutions. Previously senior director of listing at Huobi Global, and currently head of research at MEXC exchange. ',
  },
]