import Pic0 from '../../assets/images/portgolio/0.svg'
import Pic1 from '../../assets/images/portgolio/1.svg'
import Pic2 from '../../assets/images/portgolio/2.svg'
import Pic3 from '../../assets/images/portgolio/3.svg'
import Pic4 from '../../assets/images/portgolio/4.svg'
import Pic5 from '../../assets/images/portgolio/5.svg'
import Pic6 from '../../assets/images/portgolio/6.svg'
import Pic7 from '../../assets/images/portgolio/7.svg'
import Pic8 from '../../assets/images/portgolio/8.svg'
import Pic9 from '../../assets/images/portgolio/9.svg'
import Pic10 from '../../assets/images/portgolio/10.svg'
import Pic11 from '../../assets/images/portgolio/11.svg'
import Pic12 from '../../assets/images/portgolio/12.svg'
import Pic13 from '../../assets/images/portgolio/13.svg'
import Pic14 from '../../assets/images/portgolio/14.svg'
import Pic15 from '../../assets/images/portgolio/15.svg'
import Pic17 from '../../assets/images/portgolio/17.svg'

export const projects = [{
  name: 'xDai Chain',
  icon: Pic0,
  url: 'https://www.xdaichain.com/'

},{
  name: 'BoringDAO',
  icon: Pic1,
  url: 'https://www.boringdao.com/'
},{
  name: 'Stratos',
  icon: Pic2,
  url: 'https://www.thestratos.org/'
},{
  name: 'Divergence',
  icon: Pic3,
  url: 'https://www.divergence-protocol.com/'
},{
  name: 'Florence',
  icon: Pic4,
  url: 'https://florence.finance/'
},{
  name: 'Risedle',
  icon: Pic5,
  url: 'https://risedle.com/'
},{
  name: 'Myso',
  icon: Pic6,
  url: 'https://www.myso.finance/'
},{
  name: 'Clearpool',
  icon: Pic7,
  url: 'https://clearpool.finance/'
},{
  name: 'Oiler',
  icon: Pic8,
  url: 'https://www.oiler.network/'
},{
  name: 'Izumi',
  icon: Pic9,
  url: 'https://izumi.finance/home'
},{
  name: 'GalleonDAO',
  icon: Pic10,
  url: 'https://www.galleon.community/'
},{
  name: 'PrimeDAO',
  icon: Pic11,
  url: 'https://www.prime.xyz/'
},{
  name: 'DeFimons',
  icon: Pic12,
  url: 'https://www.defimons.com/'
},{
  name: 'AscendEx',
  icon: Pic13,
  url: 'https://ascendex.com/en/global-digital-asset-platform'
},{
  name: 'Dexguru',
  icon: Pic14,
  url: 'https://dex.guru/'
},{
  name: 'Oneland',
  icon: Pic15,
  url: 'https://mobile.twitter.com/OneLand_World'
},{
  name: 'OVO',
  icon: Pic17,
  url: 'https://www.ovo.space/'
}]