import { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from '@mui/material'
import { peoples } from './config'

import { Container } from '../Portgolio'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 100px;
  text-transform: uppercase;
`
const Left = styled.div`
  margin-top: 10px;
`
const Right = styled.div`
  width: 830px;
  margin-left: 30px;
  margin-top: 40px;
  @media screen and (max-width: 899px) {
    width: 100%;
    margin-left: 0;
    text-align: center;
    img {
      width: 105px;
    }
  }
`
const Abbreviation = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
  p {
    margin-left: 30px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5)
  }
  img {
    width: 108px;
  }
  &.active {
    img {
      border: 3px solid black;
      border-radius: 50%;
    }
    p {
      color: rgba(0, 0, 0, 1)
    }
  }
`
const Info = styled.div`
  display: flex;
  background-color: rgba(207, 204, 194, 0.2);
  border-radius: 30px;
  padding: 0 55px;
  height: 520px;
  h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  p {
    font-size: 12px;
    line-height: 34px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div:last-child {
    margin-left: 35px;
  }
  @media screen and (max-width: 899px) {
    padding: 20px;
    flex-direction: column;
    height: auto;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 30px;
    h3 {
      font-size: 20px;
      margin-top: 20px;
    }
    div:last-child {
      margin-left: 0;
    }
  }
`

export default function Team() {
  const [index, setIndex] = useState<number>(0)
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('md'))
  return (
    <Container>
      <p></p>
      <Wrapper>
        {
          matches && <Left>
            {peoples.map((i, k) => {
              return (
                <Abbreviation onMouseMove={() => setIndex(k)} key={k} className={index === k ? 'active' : ''}>
                  <img src={i.icon} alt="" />
                  <p>{i.name}</p>
                </Abbreviation>
              )
            })}
          </Left>
        }
        <Right>
          {peoples.map((i, k) => {
            return (
              (k === index || !matches) && <Info key={k}>
                <div>
                  <img width={'270px'} src={i.img} alt="" />
                </div>
                <div>
                  <h3>{i.name}</h3>
                  <p>{i.desc}</p>
                </div>
              </Info>
            )
          })}
        </Right>
      </Wrapper>
    </Container>
  )
}
